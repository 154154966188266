<template>
  <div>
    <loader v-if="loadingState" />
    <v-row v-else class="pa-9">
      <v-col cols="12" lg="6" md="8" sm="10" class="mx-auto pa-9 border">
        <v-card-title>School Information</v-card-title>
        <v-form ref="form">
          <v-row>
            <v-col cols="12">
              <errors :errors="errors" />
            </v-col>
            <!-- School Name -->
            <v-col cols="12" class="d-flex">
              <v-textarea
                outlined
                v-model="formData.name"
                label="School Name"
                placeholder="Enter School Name"
                counter="255"
                clearable
              >
              </v-textarea>
              <span style="color: red">*</span>
            </v-col>
            <!-- Alternative School Name -->
            <v-col cols="12" class="d-flex">
              <v-textarea
                outlined
                v-model="formData.alternativeName"
                label="Alternative Name"
                placeholder="Enter School Name (alternative)"
                counter="255"
                clearable
              >
              </v-textarea>
            </v-col>

            <!-- School Address -->
            <v-col cols="12" class="d-flex">
              <v-textarea
                outlined
                v-model="formData.address"
                label="School Address"
                placeholder="Enter School Address"
                counter="255"
                clearable
              >
              </v-textarea>
              <span style="color: red">*</span>
            </v-col>

            <!-- School EIIN -->
            <v-col cols="12" class="d-flex">
              <v-text-field
                outlined
                v-model="formData.eiin"
                label="EIIN"
                placeholder="Enter EIIN"
                clearable
              >
              </v-text-field>
              <span style="color: red">*</span>
            </v-col>

            <!-- School Banner -->
            <v-col cols="12">
              <div class="d-flex">
                <v-file-input
                  label="School Banner"
                  outlined
                  prepend-icon=""
                  accept="image/*"
                  @change="imageHandler($event, 'banner')"
                  show-size
                  clearable
                ></v-file-input>
              </div>
              <div>
                <v-img
                  v-if="formData.banner"
                  width="100%"
                  height="200px"
                  contain
                  :src="formData.banner"
                  style="border-radius: 5px; position: relative"
                >
                  <v-btn
                    color="error"
                    rounded
                    style="position: absolute; top: 0; right: 0"
                    @click="imageRemoveHandler('banner')"
                  >
                    x
                  </v-btn>
                </v-img>
              </div>
            </v-col>
            <!-- School Logo -->
            <v-col cols="12">
              <div class="d-flex">
                <v-file-input
                  label="School Logo"
                  outlined
                  prepend-icon=""
                  accept="image/*"
                  @change="imageHandler($event, 'logo')"
                  show-size
                  clearable
                ></v-file-input>
                <span style="color: red">*</span>
              </div>
              <div>
                <v-img
                  v-if="formData.logo"
                  width="100%"
                  height="200px"
                  contain
                  :src="formData.logo"
                  style="border-radius: 5px"
                ></v-img>
              </div>
            </v-col>

            <!-- Special Logo -->
            <v-col cols="12">
              <v-file-input
                label="Promotional Image"
                outlined
                prepend-icon=""
                accept="image/*"
                @change="imageHandler($event, 'secondLogo')"
                show-size
                clearable
              ></v-file-input>
              <div>
                <v-img
                  v-if="formData.secondLogo"
                  width="100%"
                  height="200px"
                  contain
                  :src="formData.secondLogo"
                  style="border-radius: 5px; position: relative"
                >
                  <v-btn
                    color="error"
                    rounded
                    style="position: absolute; top: 0; right: 0"
                    @click="imageRemoveHandler('secondLogo')"
                  >
                    x
                  </v-btn>
                </v-img>
              </div>
            </v-col>

            <!-- Second Logo Redirection Link -->
            <v-col cols="12" class="d-flex">
              <v-text-field
                outlined
                v-model="formData.secondLogoUrl"
                label="Promotional Logo Redirection Link"
                placeholder="Enter Link"
                counter="255"
                clearable
              >
              </v-text-field>
            </v-col>

            <!-- Facebook Link -->
            <v-col cols="12" class="d-flex">
              <v-text-field
                outlined
                v-model="formData.facebookLink"
                label="Facebook Link"
                placeholder="Enter Link"
                counter="255"
                clearable
              >
              </v-text-field>
            </v-col>
            <!-- Youtube Link -->
            <v-col cols="12" class="d-flex">
              <v-text-field
                outlined
                v-model="formData.youtubeLink"
                label="Youtube Link"
                placeholder="Enter Link"
                counter="255"
                clearable
              >
              </v-text-field>
            </v-col>

            <!-- Button -->
            <v-col style="text-align: right">
              <v-btn color="primary" @click="saveButtonHander"> Save </v-btn>
            </v-col>
          </v-row>
        </v-form>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import * as api from "../config/api.js";
export default {
  beforeMount() {
    this.getSchoolInformation();
  },
  components: {},
  computed: {},
  data() {
    return {
      loadingState: false,
      errors: [],
      formData: {
        name: "",
        alternativeName: "",
        address: "",
        eiin: "",
        banner: "",
        logo: "",
        secondLogo: "",
        secondLogoUrl: "",
        facebookLink: "",
        youtubeLink: "",
      },
    };
  },
  methods: {
    imageHandler(event, property) {
      let reader = new FileReader();
      if (event) {
        reader.readAsDataURL(event);
        reader.addEventListener("load", () => {
          this.formData[property] = reader.result;
        });
      } else {
        this.formData[property] = "";
      }
    },
    saveButtonHander() {
      this.loadingState = true;
      this.$http
        .post(api.SCHOOL_INFORMATION, { ...this.formData })
        .then((res) => {
          console.log("data===>", res.data);
          alert("saved");
          this.formData = res.data;
          this.errors = [];
        })
        .catch((error) => {
          alert("Error Occurred");
          console.log(" Error=======>", error.response.data);
          if (error.response.status == 403) {
            this.errors = error.response.data;
            window.scrollTo(0, 0);
          }
        })
        .finally(() => {
          this.loadingState = false;
        });
    },
    getSchoolInformation() {
      this.loadingState = true;
      this.$http
        .get(api.SCHOOL_INFORMATION)
        .then((res) => {
          console.log("Res==>", res.data);
          if (res.data) {
            this.formData = res.data;
          }
        })
        .catch((error) => {
          alert("Error Occurred");
          console.log(" Error=======>", error.response.data);
        })
        .finally(() => {
          this.loadingState = false;
        });
    },
    imageRemoveHandler(property) {
      if (confirm("Do you want to proceed?")) {
        if (this.formData[property].includes("uploads")) {
          this.loadingState = true;
          this.$http
            .delete(api.SCHOOL_INFORMATION_DELETE_IMAGE + "/" + property)
            .then((res) => {
              alert("deleted");
            })
            .catch((error) => {
              alert("Error Occurred");
              console.log(" Error=======>", error.response.data);
            })
            .finally(() => {
              this.loadingState = false;
            });
        }
        this.formData[property] = null;
      }
    },
  },
  mounted() {},
  props: {},
  updated() {},
  watch: {},
};
</script>

<style scoped></style>>
